
html {
    height: 100%;
}

body {
    font-family: 'Lato', sans-serif;
    color: #12296c;
    background: rgba(18,41,108,1);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
}


h1,h2,h3, h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

h1 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.2rem;
}

    h3.category-parent-heading {
        margin-left: 19px;
        margin-top: 6px;
        color: #00b1ec;
    }


h4 {
    font-size: 1.1rem;
    color: #00b1ec;
}

.card .card-header {
    background-color: white;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    border-bottom-width: 0;
}

.card {
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.45);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.45);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.45);
    margin-bottom: 1rem;
}

.budget-summary-section {
    padding-top: 6px;
}

.budget-summary .divider {
    border-bottom: 3px solid #dbdbdb;
    padding-top: 2px;
    padding-bottom: 2px;
}



.budget-submission-header {
    color: white;
    padding: 30px 12px;
}



.income-container .card h3 {
    color: #00b1ec;
}


.budget-summary.card .card-header, .submission-details.card .card-header {
    color: white;
    background-color: #00b1ec;
    font-weight: bold;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
}

.budget-summary.card .card-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1.25rem;
    line-height: 1.8rem;
}

    .budget-summary.card .card-header h3, .submission-details.card .card-header h3 {
        margin-bottom: 0;
        text-transform: uppercase;
    }


.submission-success.card {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    text-align:center;
    font-weight: bold;
}

.submission-success.card card-body {
    padding: 10px;
}

    .submission-success.card h4, .submission-success.card h3 {
        color: #00b1ec;
    }

        .submission-success.card img {
            width: 34px;
            margin-bottom: 12px;
        }

        .income-container .card-header {
            height: 100px;
        }



.submission-details-display {
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    margin-top: 16px;
    margin-bottom: 16px;
    background: white;
    font-weight: bold;
    line-height: 1rem;
    display: flex;
}

    .submission-details-display .heading-panel h3 {
        border-top-left-radius: 0.4rem;
        background-color: #00b1ec;
        color: white;
        padding: 12px 10px 12px 15px;
        margin: 0;
    }

    .submission-details-display .heading-panel {
        min-width: 200px;
    }

    .submission-details-display .body-panel {
        font-size: 1rem;
        display: flex;
        flex-wrap: wrap;
        margin-left: 15px;
        margin-top: 5px;
    }

        .submission-details-display .heading-panel > div {
            margin: 4px 0px 10px 15px;
        }

        .submission-details-display .body-panel > div {
            margin: 0px 25px 10px 0px;
        }


    .submission-details-display label {
        font-size: 0.7rem;
        color: #00b1ec;
        text-transform: uppercase;
        margin-bottom: 0;
    }





.expense-category-item.card .card-header {
    color: #12296c;
    font-weight: bold;
    line-height: 34px;
}

    .expense-category-item.card .card-header img {
        width: 34px;
        margin-right: 6px;
    }

.expense-category-item.card .card-header {
}


.expense-category-item .row.form-group {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

    .expense-category-item .row.form-group:not(:last-child) {
        border-bottom: 1px solid #dbdbdb;
    }





.income-category-item.card .card-header {
    color: #12296c;
    font-weight: bold;
    line-height: 34px;
}

    .income-category-item.card .card-header img {
        width: 34px;
        margin-right: 6px;
    }

.income-category-item.card .card-header {
}


.income-category-item .row.form-group {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

    .income-category-item .row.form-group:not(:last-child) {
        border-bottom: 1px solid #dbdbdb;
    }



.manage-category-item .card-header {
    color: white;
    background-color: #00b1ec;
    font-weight: bold;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1.25rem;
    line-height: 1.8rem;
}

.manage-category-item.card {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
    margin-bottom: 1rem;
}



.manage-category-item .row.form-group {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

    .manage-category-item .row.form-group:not(:first-child) {
        border-top: 1px solid #dbdbdb;
    }

    .manage-category-item .row.form-group:last-child {
        border-top: 2px solid #00b1ec;
        font-weight: bold;
        text-transform: uppercase;
    }


.tag-expense-item .card-header {
    color: white;
    background-color: #00b1ec;
    font-weight: bold;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1.25rem;
    line-height: 1.8rem;
}

.tag-expense-item.card {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
    margin-bottom: 1rem;

    min-width: 200px;
}

    .tag-expense-item.card.bills, .tag-expense-item.card.everyday_living {
        min-height: 710px;
    }

    .tag-expense-item.card.children, 
    .tag-expense-item.card.holidays, 
    .tag-expense-item.card.emergencies, 
    .tag-expense-item.card.bills, 
    .tag-expense-item.card.everyday_living {
        margin-bottom: 79px;
    }

    .tag-expense-item.card.bills .card-header {
        background-color: #85b840;
    }

    .tag-expense-item.card.everyday_living .card-header {
        background-color: #f9a227;
    }

    .tag-expense-item.card.holidays .card-header {
        background-color: #0098b7;
    }

    .tag-expense-item.card.children .card-header {
        background-color: #009687;
    }

    .tag-expense-item.card.emergencies .card-header {
        background-color: #ee376c;
    }


@media screen and (max-width: 990px) {
    .tag-expense-item.card.children,
    .tag-expense-item.card.holidays,
    .tag-expense-item.card.emergencies,
    .tag-expense-item.card.bills,
    .tag-expense-item.card.everyday_living {
        margin-bottom:20px;
    }
}

.tag-expense-item .row.form-group {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

    .tag-expense-item .row.form-group:not(:first-child) {
        border-top: 1px solid #dbdbdb;
    }

    .tag-expense-item .row.form-group:last-child {
        border-top: 2px solid #00b1ec;
        font-weight: bold;
        text-transform: uppercase;
    }

.top-menu {
    float: right;
    padding: 15px 0 0 0;
}

.top-menu a {
    color: #00b1ec;
}

.top-menu .home-link {
    background-color: #12296c;
    border-radius: 20px;
}

    .top-menu .home-link img {
        width: 23px;
    }

    .top-menu .home-link a {
        color: white;
    }


    .container-fluid {
        height: 100vh;
        background-color: #dbdbdb;
    }

.left-menu {
    width: 220px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    border-right: 1px solid #eee;
    background-color: #12296c;
    color: white;
}

    .left-menu h2 {
        font-size: 1rem;
        color: #00b1ec;
        padding-left: 6px;
        margin-top: 18px;
        margin-bottom: 2px;
    }

    .left-menu .nav .nav-item img {
        width: 30px;
        float: left;
        padding: 2px 6px 0px 4px;
    }

    .left-menu .nav .nav-item span {
        display: inline-block;
        float: left;
        width: 128px;
    }

    .left-menu .nav .nav-link.active {
        background-color: #2f4995;
    }

    .left-menu .nav .nav-link {
        padding-left: 0rem;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }

    .main {
        padding-right: 10px;
        padding-left: 220px; /* 180 + 40 */
    }



.ag-theme-alpine .ag-header {
    background-color: #00b1ec;
}

.ag-theme-alpine .ag-header-cell-text {
    color: white;
}

.ag-theme-alpine .ag-header-icon {
    color: white;
}


.ag-theme-alpine .btn.view-button {
    background-color: #8bc53f;
    border-color: #8bc53f;
    text-transform: uppercase;
    line-height: 0.8rem;
    margin-right: 4px;
    font-size: 0.8rem;
    margin-bottom: 4px;
}


.ag-theme-alpine .btn.delete-button {
    background-color: #be1e2d;
    border-color: #be1e2d;
    text-transform: uppercase;
    line-height: 0.8rem;
    margin-right: 4px;
    font-size: 0.8rem;
    margin-bottom: 4px;
}

.btn.export-button {
    background-color: #00b1ec;
    border-color: #00b1ec;
    text-transform: uppercase;
    line-height: 0.8rem;
    font-size: 0.8rem;
    margin-bottom: 4px;
}

.ag-theme-alpine .btn.export-button {
    margin-bottom: 4px;
}